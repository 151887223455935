import React, { useState } from "react";

import { Flex, Text, Image } from "@chakra-ui/react";

import PrivacyModal from "../components/privacy-modal";
import iON_white_logo from "../resources/images/iON_white_logo.png";

const Footer = () => {
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);

  return (
    <>
      <Flex
        direction={{
          base: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
          "2xl": "row",
        }}
        width="100%"
        justifyContent="space-evenly"
        alignItems="center"
        height="200px"
        as="footer"
      >
        <Flex direction="column" alignItems="center">
          <Image
            alt="ion-logo"
            height="100px"
            width="auto"
            src={iON_white_logo}
          />
        </Flex>

        <Flex direction="column">
          <Text variant="contrastColor" fontSize="2xl" mb="4">
            Policy
          </Text>
          <Text
            variant="contrastColor"
            className="footer-content"
            onClick={() => setPrivacyModalOpen(true)}
          >
            Privacy Policy
          </Text>
        </Flex>
      </Flex>

      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
      />
    </>
  );
};

export default Footer;
