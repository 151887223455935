import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Flex, Button, Container, Image } from "@chakra-ui/react";

import iON_logo from "../resources/images/iON_logo.png";
import SectionsContext from "../resources/SectionContext";
import { HEADERS } from "../resources/constants";

const Header = () => {
  const { scrollTo } = useContext(SectionsContext);

  const handleClick = (key) => {
    scrollTo(key);
  };

  return (
    <Flex
      width="100%"
      justifyContent="center"
      position="sticky"
      top="0"
      zIndex="50"
    >
      <Container
        as="header"
        maxWidth="100%"
        zIndex="1"
        display="flex"
        direction="row"
        alignItems="center"
        pt="0"
        pb="0"
        height="80px"
        justifyContent="space-between"
        position="absolute"
      >
        <Image
          id="headerLogo"
          alt="ion-logo"
          height="35px"
          width="auto"
          style={{ transition: "opacity 0.3s ease-in-out" }}
          src={iON_logo}
        />

        <Flex
          gap={{
            base: "none",
            sm: "none",
            md: "20px",
            lg: "50px",
            xl: "50px",
            "2xl": "50px",
          }}
        >
          <Button
            variant="outline"
            minHeight={50}
            minWidth={150}
            onClick={() => handleClick(HEADERS.contactUs)}
          >
            <NavLink>{HEADERS.contactUs}</NavLink>
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Header;
