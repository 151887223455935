import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#fdfdfd",
      },
      header: {
        bg: "#fdfdfd",
      },
      footer: {
        bg: "#2B3759",
      },
    },
  },
  fonts: {
    body: "'Montserrat', sans-serif",
  },
  components: {
    Text: {
      baseStyle: {
        color: "#2B3759",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: {
          base: "16px",
          sm: "16px",
          md: "18px",
          lg: "20px",
          xl: "20px",
          "2xl": "20px",
        },
      },
      variants: {
        subtitle: {
          fontSize: {
            base: "18px",
            sm: "18px",
            md: "20px",
            lg: "24px",
            xl: "24px",
            "2xl": "24px",
          },
          // textAlign: {
          //   base: "left",
          //   sm: "left",
          //   md: "left",
          //   lg: "left",
          //   xl: "justify",
          //   "2xl": "justify",
          // },
          fontWeight: "bold",
        },
        title: {
          fontSize: {
            base: "24px",
            sm: "30px",
            md: "40px",
            lg: "45px",
            xl: "45px",
            "2xl": "45px",
          },
          fontWeight: "bold",
        },
        paragraph: {
          fontSize: {
            base: "15px",
            sm: "15px",
            md: "15px",
            lg: "17px",
            xl: "17px",
            "2xl": "17px",
          },
          // textAlign: {
          //   base: "left",
          //   sm: "left",
          //   md: "justify",
          //   lg: "justify",
          //   xl: "justify",
          //   "2xl": "justify",
          // },
        },
        contrastColor: {
          color: "#ffffff",
          fontSize: {
            base: "15px",
            sm: "15px",
            md: "15px",
            lg: "17px",
            xl: "17px",
            "2xl": "17px",
          },
          textAlign: "center",
        },
      },
    },
    Button: {
      baseStyle: {
        fontFamily: "'Montserrat', sans-serif",
      },
      variants: {
        outline: {
          bg: "#2B3759",
          color: "#ffffff",
          _hover: {
            bg: "#1f2a45",
            _disabled: {
              bg: "#aaafbd",
              color: "#ffffff",
            },
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: "#2B3759",
          },
          borderColor: "#2B3759",
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: "#ffffff",
          color: "#2B3759",
          borderRadius: 0,
        },
        closeButton: {
          color: "#2B3759",
          fontSize: "18px",
          boxSize: "27px",
          outline: "2px solid #2B3759",
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          background: "gray.50",
          color: "#2B3759",
        },
      },
    },
    //TODO: FIX THIS
    Input: {
      baseStyle: {
        field: {
          bg: "gray.50",
          color: "#2B3759",
        },
      },
    },
  },
});
