import React from "react";
import { BrowserRouter } from "react-router-dom";

import HomePage from "./home-page";
import Header from "./header";

const BodyContent = () => {
  return (
    <>
      <BrowserRouter>
        <Header />

        <HomePage />
      </BrowserRouter>
    </>
  );
};

export default BodyContent;
