import React, { useState, useMemo } from "react";

import { Box, Flex, Text, Image } from "@chakra-ui/react";

import CoreAccordion from "./core/CoreAccordion";
import CoreSectionContainer from "./core/CoreSectionContainer";
import accordion1 from "../resources/images/accordion1.gif";
import accordion2 from "../resources/images/accordion2.gif";
import accordion3 from "../resources/images/accordion3.gif";
import accordion4 from "../resources/images/accordion4.gif";
import accordion5 from "../resources/images/accordion5.gif";

const accordionsData = [
  {
    index: 1,
    title: "EMPOWERING SMART BUSINESS CONVERSATIONS",
    text: "Implement iON's conversational automation to tap into new markets and strengthen ongoing client relationships, directly impacting revenue growth.",
  },
  {
    index: 2,
    title: "ENHANCE CLIENT ONBOARDING",
    text: "Our platform's multi-channel conversational interface opens up fresh avenues for user engagement, optimizing customer acquisition strategies.",
  },
  {
    index: 3,
    title: "MINIMIZE CUSTOMER ATTRITION",
    text: "iON's advanced conversational AI fosters a self-help culture that enhances customer satisfaction and loyalty.",
  },
  {
    index: 4,
    title: "ELEVATE USER EXPERIENCES",
    text: "Seamless interactions anytime, anywhere - iON ensures that every user interaction is as smooth and effective as possible, enhancing overall user and business experience.",
  },
  {
    index: 5,
    title: "CUT OPERATIONAL EXPENSES",
    text: "Efficiency Through AI – by integrating conversational AI, iON significantly lowers the consumption of resources across your business processes, reducing costs and improving operational efficiency.",
  },
];

const videos = [
  {
    src: accordion1,
    index: 0,
    alt: "document-upload-feature",
  },
  {
    src: accordion2,
    index: 1,
    alt: "engine-settings-feature",
  },
  {
    src: accordion3,
    index: 2,
    alt: "edit-chatbot-feature",
  },
  {
    src: accordion4,
    index: 3,
    alt: "add-link-feature",
  },
  {
    src: accordion5,
    index: 4,
    alt: "user-access-feature",
  },
];

const WayWeWork = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const selectedVideo = useMemo(() => {
    return videos[selectedTab] || videos[0];
  }, [selectedTab]);

  return (
    <CoreSectionContainer disablePaddingLeftRight={true} isCustomSlide={true}>
      <Flex flexDir="column" width="100%">
        <Box
          mt="16"
          mb="5"
          pr={{
            base: "30",
            sm: "30",
            md: "30",
          }}
          pl={{
            base: "30",
            sm: "30",
            md: "30",
            lg: "0",
            xl: "0",
          }}
        >
          <Text variant="title">Revolutionizing the Way We Work</Text>
        </Box>

        <Flex
          gridGap={{
            base: "40px",
            sm: "40px",
            md: "40px",
            lg: "10px",
            xl: "10px",
          }}
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          }}
        >
          <Box
            width="100%"
            pr={{
              base: "0",
              sm: "0",
              md: "0",
            }}
            pl={{
              base: "0",
              sm: "0",
              md: "0",
            }}
          >
            <CoreAccordion
              data={accordionsData}
              setSelectedTab={setSelectedTab}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            pr={{
              base: "30",
              sm: "30",
              md: "30",
            }}
            pl={{
              base: "30",
              sm: "30",
              md: "30",
            }}
          >
            <Image
              alt={selectedVideo.alt}
              src={selectedVideo.src}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </CoreSectionContainer>
  );
};

export default WayWeWork;
