const notificationVariants = {
  success: "success",
  warning: "warning",
  info: "info",
  error: "error",
};

export function useNotification(toast, props) {
  const showMessage = (
    title,
    description,
    status = notificationVariants.info
  ) => {
    toast({
      duration: 2000,
      position: "bottom-right",
      title,
      description,
      status,
      ...(props || {}),
    });
  };

  const error = (message, description = "") => {
    showMessage(message, description, notificationVariants.error);
  };

  const success = (message, description = "") => {
    showMessage(message, description, notificationVariants.success);
  };

  return {
    error,
    success,
  };
}
