import React from "react";

import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";

import { HEADERS } from "../resources/constants";
import CoreSlide from "./core/CoreSlide";
import ContactForm from "./contact-form";

const ContactUs = () => {
  const maxWidth = useBreakpointValue({ sm: "100%", lg: "50%" });
  return (
    <CoreSlide category={HEADERS.contactUs}>
      <Flex
        flexDir="column"
        justify="center"
        h="50%"
        width="100%"
        gridGap="70px"
      >
        <Flex justifyContent="center" alignItems="center">
          <Text variant="title">CONTACT US</Text>
        </Flex>

        <Flex
          direction={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
            "2xl": "row",
          }}
          gridGap="40px"
        >
          <Flex w={maxWidth} gridGap="20px" flexDirection="column">
            <Text variant="subtitle">
              Elevate Your Business with Tailored Conversational AI Solutions
            </Text>

            <Text variant="paragraph">
              Experience a pricing model as unique as your business goals. Our
              adaptive pricing strategy is crafted to align precisely with your
              specific objectives, ensuring you achieve optimal outcomes without
              a generic, one-size-fits-all solution.
            </Text>

            <Text variant="paragraph">
              Our expert team is dedicated to guiding you swiftly towards
              significant returns. Start your journey to transformative
              conversational AI success
              <span style={{ fontWeight: "bold" }}>
                — simply fill out the form next to this message and join us in
                crafting your success story.
              </span>
            </Text>
          </Flex>

          <ContactForm />
        </Flex>
      </Flex>
    </CoreSlide>
  );
};

export default ContactUs;
