import React, { useEffect, useRef } from "react";

import { Box, Flex, Image, Text } from "@chakra-ui/react";

import { useOnScreen } from "../hooks/onScreen";
import iON_logo from "../resources/images/iON_logo.png";
import videoGif from "../resources/background/homeBg.webm";

const Home = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    const headerLogo = document.getElementById("headerLogo");

    if (isVisible) {
      headerLogo.style.opacity = 0;
    } else {
      headerLogo.style.opacity = 1;
    }
  }, [isVisible]);

  return (
    <div ref={ref}>
      <Box
        px="30"
        width="100%"
        minHeight="900px"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        position="relative"
        bg="transparent"
        overflow="hidden"
      >
        <video
          autoPlay
          muted
          playsinline
          loop
          width="auto"
          height="450px"
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "auto",
            maxHeight: "70%",
            height: "auto",
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          <source src={videoGif} type="video/webm" />
        </video>

        <Flex
          flexDir="column"
          justifyContent="flex-start"
          h="100%"
          width="100%"
          gridGap={6}
        >
          <Image
            id="homeLogo"
            alt="ion-logo"
            height="auto"
            width="100%"
            maxWidth="300px"
            src={iON_logo}
          />
          <Box textAlign="left" maxWidth="600px">
            <Text variant="title">Digital interactions as smart solutions</Text>
          </Box>
        </Flex>
      </Box>
    </div>
  );
};

export default Home;
