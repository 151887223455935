import React from "react";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
} from "@chakra-ui/react";

import PrivacyPolicy from "./privacy-and-policy";

const PrivacyModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        position="fixed"
        width="100%"
        marginTop="0"
        marginBottom="0"
        zIndex="1400"
        height="100%"
      >
        <ModalCloseButton marginTop="10px" marginRight="15px" />
        <ModalBody overflowY="auto">
          <PrivacyPolicy />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrivacyModal;
