import React from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";
import { theme } from "./resources/theming";

import BodyContent from "./app-structure/body-content";
import { SectionsProvider } from "./resources/SectionContext";
import Footer from "./app-structure/footer";
import "./App.css";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box fontSize="xl" height="100%">
        <SectionsProvider>
          <BodyContent />

          <Footer />
        </SectionsProvider>
      </Box>
    </ChakraProvider>
  );
}

export default App;
