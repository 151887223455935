import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";

const CoreAccordion = ({ data, setSelectedTab }) => {
  return (
    <Accordion
      width="100%"
      flex="1"
      minHeight="100"
      allowToggle
      onChange={(index) => setSelectedTab(index)}
    >
      {data?.map((accordion, index) => (
        <AccordionItem
          key={index}
          width="100%"
          borderTop="none"
          flex="1"
          minHeight="100"
          borderBottom="1px solid"
          borderBottomColor="#2B3759"
        >
          <AccordionButton
            width="100%"
            display="flex"
            justifyContent="space-between"
            flex="1"
            minHeight="100"
            gap="10px"
          >
            <Flex minWidth="100px" alignItems="center">
              <Text whiteSpace="normal" textAlign="left">
                {accordion.index}
              </Text>
              <Box
                flex="1"
                textAlign="left"
                whiteSpace="normal"
                width="100%"
                pl={10}
              >
                <Text fontWeight="bold">{accordion.title}</Text>
              </Box>
            </Flex>
            <AccordionIcon boxSize={30} />
          </AccordionButton>
          <AccordionPanel pb={4} width="100%" height="100%">
            <Text variant="paragraph">{accordion.text}</Text>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default CoreAccordion;
