export const HEADERS = {
  contactUs: "CONTACT US",
};

export const SECTIONS = {
  home: "HOME",
  howItWorks: "HOW IT WORKS",
  productFeatures: "PRODUCT FEATURES",
  wayWeWork: "WAY WE WORK",
  contactUs: "CONTACT US",
};

export const CONTACT = {
  company: "NENOS SOFTWARE SRL",
  address1:
    "Bulevardul Marea Unire, Nr.15, Parter, Bloc U4, Spatiu Comercial 2",
  address2: "Galați 800329, Romania",
  email: "contact@nenos-software.ro",
};
