import React from "react";

import { Flex } from "@chakra-ui/react";

const CoreSectionContainer = ({
  children,
  disablePaddingLeftRight,
  isCustomSlide,
}) => {
  return (
    <Flex
      bg="transparent"
      pt="36"
      pb="36"
      pl={
        disablePaddingLeftRight
          ? {
              base: "0",
              sm: "0",
              md: "0",
              lg: "30",
              xl: "30",
              "2xl": "30",
            }
          : "30"
      }
      pr={
        disablePaddingLeftRight
          ? {
              base: "0",
              sm: "0",
              md: "0",
              lg: "30",
              xl: "30",
              "2xl": "30",
            }
          : "30"
      }
      direction={{
        base: "column",
        sm: "column",
        md: "column",
        lg: "row",
        xl: "row",
        "2xl": "row",
      }}
      gridGap="40px"
      maxWidth={isCustomSlide ? undefined : "1500px"}
      width={isCustomSlide ? "auto" : undefined}
      marginLeft={
        isCustomSlide
          ? { base: "0", sm: "0", md: "auto", lg: "auto", xl: "100" }
          : "auto"
      }
      marginRight={isCustomSlide ? "0" : "auto"}
    >
      {children}
    </Flex>
  );
};

export default CoreSectionContainer;
