import React from "react";

import { Box, Container, Text } from "@chakra-ui/react";

import { CONTACT } from "../resources/constants";

const data = [
  {
    newLine: true,
    paragraphs: [
      "This Privacy Policy is intended to provide information on the collection, use and disclosure to third parties of personal data by Nenos Software through its product iON, a company incorporated under Romanian law with its headquarters at Bulevardul Marea Unire, Nr.15, Parter, Bloc U4, Spatiu Comercial 2, Galați 800329 Romania, registered with the Trade Register under J17/103/2012, sole registration code RO 29612482 (hereinafter “iON”).",
      "Your privacy is very important to us. Therefore, we take utmost care to process your personal data in accordance with the principles set forth in the data protection legislation applicable in Romania, including (EU) Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (“GDPR”).",
      "Personal data represents any information relating to an identified or identifiable natural or legal person and an identifiable person is that person that may be identified directly or indirectly, particularly by reference to an identification number or to one or more factors specific to its physical, psychological, mental, economic, cultural or social identity.",
      "This privacy policy concerns the personal data of our clients, suppliers, business partners, other persons contacting and visiting us and of the representatives thereof, potential employees, collaborators and it applies to data collected through our website, www.ionify.ro, as well as to all other personal data we collect through e-mail or other off-line contacts.",
    ],
  },
  {
    subtitle: "This privacy policy describes:",
    newLine: false,
    points: [
      "the categories of personal data we collect and process;",
      "the purposes for which we collect and use your personal data;",
      "the processing grounds for such purposes;",
      "the duration of processing of such data;",
      "your rights as data subjects and the manner in which you may exercise them;",
      "to whom we may disclose your personal data.",
    ],
    pointsBreak: true,
  },
  {
    subtitle:
      "I. CATEGORIES OF PERSONAL DATA; PURPOSES AND GROUNDS FOR PROCESSING",
    newLine: true,
    topParagraphBreak: true,
    bottomParagraphBreak: true,
    paragraph:
      "In the context of your interaction with iON, you, as a natural person, may be subject to the data processing activities that we perform. Please be informed that we use your personal data in the following cases:",
  },
  {
    subtitle:
      "1. If you are a representative, employee, collaborator or contact person of a iON’s client or potential client",
    newLine: true,
    paragraphs: [
      "1.1. The goals of processing your data are:",
      "1.1.1. Providing the services requested from us",
      "We use your relevant personal data in order to prepare and provide the services requested from us. For instance, we may use the personal data to manage the relationship with the client you represent. We rely in this case on the legitimate interest in providing our services according to our area of activity and managing the relationship with our customers.",
      "We may also have access, use and/or process in any other way your personal data when we act (as processor) in the name and on behalf of our client (data controller), to the extent such activity is necessary in order to perform the services contracted by our client.",
      "1.1.2. Communicating with you",
      "We use your contact details in order to communicate with you with respect to your requests and any other relevant business-related matters regarding our cooperation or possible cooperation. We also rely in this case on the legitimate interest in providing our services according to our area of activity.",
      "1.1.3. Data processing as obligation imposed by the law",
      "We may process some of your data in the context of providing the services based on legal obligations, should it be the case. In such situation, the grounds for processing is the legal obligation.",
      "1.1.4. Sending newsletters",
      "We may also use your contact details in order to provide to you in electronic form our specialized newsletters on our services as well as on the events and/or conferences organized by us and/or by third parties, but only if you have subscribed and, hence, given your express consent to such processing. You can always withdraw your consent expressing thereby your option of not receiving our newsletters in the future by clicking “Unsubscribe” when you receive the respective e-mail or by writing at contact@nenos-software.ro with your request for cancelation of your subscription or accessing the cancellation of subscription dedicated link existing at the end of each newsletter.",
    ],
  },
  {
    newLine: true,
    paragraphs: [
      "1.2. The categories of data processed in this context are generally your name position, e-mail, phone, as well as other personal data provided to iON, usually by you, if needed to fulfill the above purposes. In all cases data is provided either directly by you or by the client.",
      "In case we process your data as processor in the name and on behalf of our client (data controller), the data processed will be described in the data processing agreement concluded between us and our Client.",
    ],
  },
  {
    subtitle: "2. If you are a business partner or supplier of iON",
    newLine: true,
    paragraphs: [
      "2.1. The goals of processing your data are:",
      "2.1.1. Maintaining our contractual relationship with you",
      "We use your relevant personal data in order to maintain our contractual relationship with you. We rely in this case on the performance of our agreement as grounds for processing.",
      "2.1.2. Communicating with you",
      "We use your contact details in order to communicate with you with respect to any relevant business-related matters. We also rely in this case on the performance of our agreement as grounds for processing.",
      "2.1.3. Data processing as obligation imposed by law",
      "We may process some of your data in the context of providing the services based on legal obligations, should it be the case. In such situation, the ground of the processing is the legal obligation.",
      "2.2. The categories of data processed in the context of our relationship with you are your name e-mail, phone, address.",
    ],
  },
  {
    subtitle:
      "3. If you are a representative, contact person, employee or another collaborator of a iON's business partner",
    newLine: true,
    paragraphs: [
      "3.1. The goals of processing your data are:",
      "3.1.1 Maintaining our contractual relationship with our business partner",
      "We use your relevant personal data in order to maintain our contractual relationship with our business partner (including a supplier). We rely in this case on the legitimate interest in ensuring that the contractual relationship is managed properly.",
      "3.1.2. Communicating with you",
      "We use your contact – details in order to communicate with you with respect to any relevant matters related to our partnership. We also rely in this case on the legitimate interest of ensuring that the contractual relationship is adequately carried out.",
      "3.1.3. Data processing as obligation imposed by law",
      "We may process some of your data in the context of providing the services based on legal obligations. In such case, the grounds for processing is the legal obligation.",
      "3.2. The categories of data processed in this context are generally: name, position, e-mail, phone, address, as well as other personal data provided to iON, usually by you, as needed to fulfill the above purposes.",
      "In all cases, the data is provided either directly by you to iON or or by the business partner of iON.",
    ],
  },
  {
    subtitle: "4. If you are a job applicant",
    newLine: true,
    topParagraphBreak: true,
    bottomParagraphBreak: true,
    paragraph:
      "We use the personal data contained in the CVs we receive in order to assess the applicants’ qualifications for a position within iON. We base our processing on the grounds of entering into and performing of the agreement. The categories of data processed in the context of our relationship with you are your name, e-mail, phone, address, personal data included in CVs, education and training details, professional qualifications.",
  },
  {
    subtitle: "5. If you are a user of our Internet website",
    newLine: true,
    paragraphs: [
      "We use the personal data we collect from you when you visit our Internet site, www.ionify.ro, in order to process and respond to your inquiries transmitted via our website as well as in order to send the newsletters you subscribe for while visiting our website.",
      "We base this data processing activity on our legitimate interest in giving the information requested in respect to our activity and services. The categories of data processed in this context are the name, email address and phone number.",
      "We also use your personal data in order monitor the traffic and improve the content of the website. We base this data processing activity on our legitimate interest in ensuring the proper functioning of our Internet website, as well as its improvement.",
      "We use web analysis and report services (Analytics) in order to analyze information collected from the users of the website. This information allows iON to better understand users of its website and the way they use the page.",
      "The categories of data processed in this context are the hour and date of accessing the Internet website and the IP address from which our Internet website was accessed.",
    ],
  },
  {
    subtitle: "II. PROVIDING THE PERSONAL DATA",
    newLine: true,
    paragraphs: [
      "When the data is required directly from you, kindly asks you to provide all categories of personal data we request in the aforementioned purposes, as otherwise we shall not be able to carry out our activity (including, among others, to provide you with our services).",
      "If you provide to iON the personal data of other natural persons, we kindly ask you to communicate to them before such disclosure the modality in which iON intends to process their personal data, as described in this Privacy Policy.",
    ],
  },
  {
    subtitle: "III. DISCLOSURE OF YOUR PERSONAL DATA",
    newLine: true,
    topParagraphBreak: true,
    paragraph:
      "While as a rule we shall not disclose your personal data to any third parties, we may:",
  },
  {
    points: [
      "if necessary, transfer your data, by disclosure or providing remote access, only through secured applications, to third parties such as iON’s business partners, acting as data processors for and on behalf of iON (e.g. hosting, legal and financial advisors, technical service providers or service providers providing dispatch support), with whom iON has made the necessary contractual agreements required by EU and national regulations. We shall transfer data to third parties exclusively to the extent necessary to serve the applicable processing purposes for which your data is collected and processed.",
      "disclose the data in order to comply with the law or in response to a request from a court or other legal authority, such as a warrant or a subpoena. iON might also share the information if we believe, in good faith, that it is necessary to prevent or address frauds or protect iON.",
      "if necessary, disclose relevant personal data to relevant authorities, in the context of providing the services you have requested from us.",
    ],
    pointsBreak: true,
  },
  {
    subtitle: "IV. DURATION OF PROCESSING",
    newLine: true,
    paragraphs: [
      "We intend to keep your personal data for the duration of the agreement concluded with you, as well as afterwards, according to our internal policies and the legal obligations incumbent upon us. Also, iON shall maintain your personal data to s for a period which is necessary in order to preserve its rights and legitimate interests. In case the data is not collected in the context of an agreement, such data will be kept for as long as necessary in order to attain the purpose of the envisaged data collection or any other longer period imposed by the law, and applicable records retention regulation or public authorities.",
      "Promptly after the applicable storage period has ended, the data shall be:",
    ],
  },
  {
    points: ["securely deleted or destroyed;"],
  },
  {
    newLine: true,
    paragraph: "or",
  },
  {
    points: [
      "transferred to an archive (unless this is prohibited by law or an applicable records retention regulation).",
    ],
  },
  {
    newLine: true,
    paragraph:
      "Personal data collected and used for subscription to our newsletter will be immediately deleted if you unsubscribe from our email newsletter service.",
    topParagraphBreak: true,
    bottomParagraphBreak: true,
  },
  {
    subtitle: "V. YOUR RIGHTS",
    newLine: true,
    paragraph:
      "In your capacity as data subjects, the GDPR provides you with a series of rights including:",
    topParagraphBreak: true,
    bottomParagraphBreak: true,
    points: [
      "the right of access – allowing you to obtain confirmation that your personal data is being processed by us and, if affirmative, the relevant details of such processing activities;",
      "the right to rectification – allowing you to rectify your personal data if inaccurate;",
      "right to erasure – allowing you to obtain the erasure of your personal data in certain cases (e.g., if the data is no longer necessary in relation to the purposes for which it was collected);",
      "right to restriction – allowing you to obtain the restriction of processing your personal data in certain cases (e.g., when you contest the accuracy of your personal data, for a period enabling us to verify such accuracy);",
      "the right to object – allowing you to object to further processing of your personal data within the conditions and limits set forth by law;",
      "the right to object – allowing you to object to further processing of your personal data within the conditions and limits set forth by law;",
    ],
  },
  {
    newLine: true,
    paragraphs: [
      "We are happy to ensure your exercise of these rights.",
      "You may exercise your aforementioned rights freely and at any time and find out more about such rights by filing with us, as data controller, a written request at Nenos Software, Bulevardul Marea Unire, Nr.15, Parter, Bloc U4, Spatiu Comercial 2, Galați 800329, Romania, or by e-mailing us at contact@nenos-software.ro",
    ],
  },
  {
    newLine: true,
    paragraphs: [
      "You also have the right to file a complaint with the data protection authority.",
      "We encourage you to contact us immediately if you believe that your personal data was provided without consent. We are committed to always treat your requests with the utmost attention and address any queries you may have in the shortest time possible.",
    ],
  },
  {
    subtitle: "VI. SECURITY",
    newLine: true,
    paragraphs: [
      "As part of the administration of our website, we have taken technical and organizational measures to ensure a level of security appropriate to the risks that are presented by processing, in particular from misuse or accidental, unlawful or unauthorized destruction, loss, alteration, disclosure, acquisition or access, willful or accidental manipulation, access by third parties, deletion and modification.",
      "You shall be notified with respect to a data security breach, within a reasonable period of time following discovery of such breach, unless a law enforcement official determines that notification would impede a criminal investigation or cause damage to national security. In this case, notification shall be delayed as instructed by such law enforcement official. We shall promptly respond to your enquiries relating to such data security breach.",
    ],
  },
  {
    subtitle: "VII. CHANGES",
    newLine: true,
    paragraphs: [
      "This Privacy Policy may be updated from time to time.",
      "When we do so, we will post those changes on our website, so you can always have access to details about the information we collect, how we use it, and under what circumstances, if any, we disclose it. Any such change, material or otherwise, shall enter into force as of publication of the new version on our website.",
      "If at any point we decide to use personal Data in a manner different from that stated at the time it was collected, we will notify you by e-mail. You will have a choice as to whether or not we may use your data in this different manner. Otherwise, if possible, we will use the data in accordance with the Privacy Policy under which the data was collected.",
    ],
  },
  {
    subtitle: "VIII. OTHER WEBSITES",
    newLine: true,
    paragraphs: [
      "Our website contains links to other websites. The present Privacy Policy applies only for this website, therefore when you access other websites you must read their privacy policies.",
    ],
  },
  {
    subtitle: "IX. CONTACT INFORMATION",
    newLine: true,
    paragraphs: [
      "If you have any further questions about how we process your personal information, or if you would like to exercise any of your rights under this policy, please send us a written request, using the following contact details:",
    ],
  },
  {
    newLine: true,
    paragraphs: [
      "Address:",
      CONTACT.company,
      CONTACT.address1,
      CONTACT.address2,
      `Email: ${CONTACT.email}`,
    ],
  },
  {
    newLine: true,
    topParagraphBreak: true,
    bottomParagraphBreak: true,
    paragraph:
      "We shall ensure adequate steps are taken to address your request without undue delay and, in any event, within 30 (thirty) days of receipt of the request. The information shall be provided by electronic means where possible, unless otherwise expressly requested by you in writing.",
  },
];

const PrivacyPolicy = () => {
  return (
    <Container
      maxWidth={{
        base: "85%",
        sm: "85%",
        md: "85%",
        lg: "75%",
        xl: "70%",
        "2xl": "70%",
      }}
    >
      <Text variant="title">iON Privacy Policy</Text>

      {data?.map((item, index) => (
        <div key={index}>
          {item?.subtitle && (
            <Box fontWeight="bold">
              <Text variant="subtitle">{item?.subtitle}</Text>
            </Box>
          )}

          {item?.newLine && (
            <>
              {item?.paragraphs ? (
                <>
                  <br />
                  {item?.paragraphs?.map((paragraph, indexP) => (
                    <div key={indexP}>
                      <Text variant="paragraph">{paragraph}</Text>
                      <br />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {item?.topParagraphBreak && <br />}
                  <Text variant="paragraph">{item?.paragraph}</Text>
                  {item?.bottomParagraphBreak && <br />}
                </>
              )}
            </>
          )}

          {item?.points && (
            <>
              {item?.pointsBreak && <br />}
              <ul
                style={{
                  paddingLeft: "40px",
                }}
              >
                {item?.points?.map((point, indexP) => (
                  <li key={indexP}>
                    <Text variant="paragraph">{point}</Text>
                  </li>
                ))}
                {item?.pointsBreak && <br />}
              </ul>
            </>
          )}
        </div>
      ))}
    </Container>
  );
};

export default PrivacyPolicy;
