import React from "react";
import { Box, Text, Stack, Image } from "@chakra-ui/react";

const CoreCard = ({ image, text, textVariant, title }) => {
  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      p={4}
      bg="gray.50"
      minWidth={"100%"}
      height={"100%"}
    >
      <Stack
        spacing={4}
        align="center"
        justifyContent="flex-start"
        mb={4}
        pl={{
          base: 0,
          lg: 0,
          xl: 10,
        }}
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "column",
          xl: "row",
          "2xl": "row",
        }}
      >
        <Image boxSize="55px" objectFit="cover" src={image} />
        <Text
          fontWeight="bold"
          mb={2}
          pl={{
            base: 0,
            lg: 0,
            xl: 5,
          }}
          textAlign="center"
        >
          {title}
        </Text>
      </Stack>

      <Text variant={textVariant}>{text}</Text>
    </Box>
  );
};

export default CoreCard;
