import React from "react";

import { Box, Flex, Text, SimpleGrid } from "@chakra-ui/react";

import clear_and_accurate from "../resources/images/clear_and_accurate.png";
import document_access from "../resources/images/document_access.png";
import intuitive_interface from "../resources/images/intuitive_interface.png";
import multiple_inputs from "../resources/images/multiple_inputs.png";
import text_to_speech from "../resources/images/text_to_speech.png";
import speech_to_text from "../resources/images/speech_to_text.png";
import CoreSectionContainer from "./core/CoreSectionContainer";
import CoreCard from "./core/CoreCard";

const cards = [
  {
    image: intuitive_interface,
    title: "INTUITIVE INTERFACE",
    text: "Our chatbot streamlines access to any kind of site information and institutional documents, promptly answering user queries through advanced artificial intelligence technology.",
    textVariant: "paragraph",
  },
  {
    image: document_access,
    title: "INSTANT DOCUMENT ACCESS",
    text: "Users can quickly retrieve relevant information and documents via direct chat commands, empowering them to get exactly what they need, when they need it.",
    textVariant: "paragraph",
  },
  {
    image: speech_to_text,
    title: "SPEECH TO TEXT",
    text: "Enables verbal questions to simplify interactions for a natural experience. The feature is particularly beneficial for visually impaired users or those who favor speaking over typing.",
    textVariant: "paragraph",
  },
  {
    image: clear_and_accurate,
    title: "CLEAR AND ACCURATE",
    text: "We provide links to the sources of our responses to ensure the transparency and accuracy of the information presented.",
    textVariant: "paragraph",
  },
  {
    image: multiple_inputs,
    title: "MULTIPLE INPUTS",
    text: "Streamlines data collection from websites, help centers, and archives, enabling timely updates for sustained relevance and strategic learning.",
    textVariant: "paragraph",
  },
  {
    image: text_to_speech,
    title: "TEXT TO SPEECH",
    text: "This feature enables users to interact with the chatbot through voice commands, enhancing the user experience with a more intuitive approach.",
    textVariant: "paragraph",
  },
];

const ProductFeatures = () => {
  return (
    <CoreSectionContainer>
      <Flex flexDir="column" h="100%" w="100%" gridGap="10px">
        <Text variant="subtitle">PRODUCT FEATURES:</Text>

        <Text variant="title">
          Smarter Conversations: Integrate AI into Your Daily Workflow
        </Text>

        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 3 }}
          spacingX={2}
          spacingY={6}
          width="100%"
        >
          {cards.map((card, index) => (
            <Box key={`${card.title}-${index}`} flexBasis="100%">
              <CoreCard {...card} />
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
    </CoreSectionContainer>
  );
};

export default ProductFeatures;
