import React, { useState, useRef } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import {
  Box,
  Button,
  Checkbox,
  Input,
  Select,
  Stack,
  Text,
  Flex,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useNotification } from "../hooks/notification";
import ApiHelper from "../app-structure/helpers/api-helper";
import PrivacyModal from "./privacy-modal";

const INITIAL_FORM = {
  help: "",
  email: "",
  name: "",
  phone: "",
};

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const ContactForm = () => {
  const recaptchaRef = useRef();
  const notification = useNotification(useToast());
  const [form, setForm] = useState(INITIAL_FORM);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [isSubmitChecked, setIsSubmitChecked] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const maxWidth = useBreakpointValue({ sm: "100%", lg: "50%" });

  const onFormUpdate = (event) => {
    const { name, value } = event.target;
    const phonePattern = /^\+?[0-9]*$/;

    if (name === "phone" && !phonePattern?.test(value)) {
      return;
    }

    setForm({ ...form, [name]: value });
  };

  const onSubmitForm = () => {
    setOperationInProgress(true);

    ApiHelper.sendRequest(form)
      .then(() => {
        notification.success(
          "Thank you for your interest!",
          "We will contact you!"
        );
        setForm(INITIAL_FORM);
        recaptchaRef?.current?.reset();
        setIsSubmitChecked(false);
      })
      .catch(() => {
        notification.error("Something went wrong!", "Please try again!");
        recaptchaRef?.current?.reset();
      })
      .finally(() => {
        setOperationInProgress(false);
      });
  };

  const handleClickPolicy = (e) => {
    e.preventDefault();
    setIsPrivacyModalOpen(true);
  };

  const isEmailInvalid =
    form.email?.trim() !== "" && !EMAIL_REGEX.test(form.email?.trim());

  const isFormValid =
    form.name?.trim() !== "" &&
    form.email?.trim() !== "" &&
    !isEmailInvalid &&
    isSubmitChecked;

  const verifyTokenRecaptcha = (token) => {
    if (!token) return;
    ApiHelper.verifyRecaptcha(token)
      .then(() => {
        setIsRecaptchaVerified(true);
      })
      .catch(() => {
        recaptchaRef?.current?.reset();
        setIsRecaptchaVerified(false);
      });
  };

  return (
    <>
      <Box width={maxWidth} padding={0}>
        <Stack spacing={4} display="flex" flexDirection="column" padding={0}>
          <Flex
            justifyContent="center"
            alignItems="center"
            gap={0}
            gridGap="15px"
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "row",
              "2xl": "row",
            }}
          >
            <Flex id="help" direction="column" width="100%">
              <Text variant="paragraph" fontWeight="bold">
                How can we help you
              </Text>

              <Select
                name="help"
                placeholder="Select option"
                width="100%"
                onChange={onFormUpdate}
                value={form.help}
                isDisabled={operationInProgress}
              >
                <option value="Discover what iON can do.">
                  Discover what iON can do.
                </option>
                <option value="Learn about iON's pricing.">
                  Learn about iON's pricing.
                </option>
                <option value="Find out how iON can help me">
                  Find out how iON can help me.
                </option>
                <option value="Get support and troubleshooting help.">
                  Get support and troubleshooting help.
                </option>
              </Select>
            </Flex>

            <Flex id="email" direction="column" width="100%">
              <Text variant="paragraph" fontWeight="bold">
                E-mail address *
              </Text>
              <Input
                name="email"
                type="email"
                width="100%"
                value={form.email}
                onChange={onFormUpdate}
                bg="gray.50"
                isDisabled={operationInProgress}
              />
            </Flex>
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            gridGap="15px"
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "row",
              "2xl": "row",
            }}
          >
            <Flex id="name" direction="column" width="100%">
              <Text variant="paragraph" fontWeight="bold">
                Name *
              </Text>
              <Input
                name="name"
                type="text"
                width="100%"
                value={form.name}
                onChange={onFormUpdate}
                bg="gray.50"
                isDisabled={operationInProgress}
              />
            </Flex>

            <Flex id="phone" direction="column" width="100%">
              <Text variant="paragraph" fontWeight="bold">
                Phone number
              </Text>
              <Input
                name="phone"
                type="tel"
                width="100%"
                value={form.phone}
                onChange={onFormUpdate}
                bg="gray.50"
                isDisabled={operationInProgress}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </Flex>
          </Flex>

          <Box
            width="100%"
            overflow="auto"
            display="flex"
            justifyContent={{
              base: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
              xl: "center",
              "2xl": "center",
            }}
            alignItems="center"
            pt="5"
            pb="5"
          >
            <ReCAPTCHA
              hl="en"
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={verifyTokenRecaptcha}
              ref={recaptchaRef}
              style={{ width: "200px" }}
              onExpired={() => setIsRecaptchaVerified(false)}
            />
          </Box>

          <Flex
            direction="row"
            alignItems="center"
            justifyContent="center"
            mt="5"
            mb="4"
          >
            <Checkbox
              id="agree-terms"
              isRequired
              isChecked={isSubmitChecked}
              isDisabled={operationInProgress}
              onChange={() => setIsSubmitChecked(!isSubmitChecked)}
            >
              <Text variant="paragraph" wordBreak="break-word">
                By submitting you agree to our{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                  }}
                  onClick={handleClickPolicy}
                >
                  Privacy Policy
                </span>{" "}
              </Text>
            </Checkbox>
          </Flex>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              variant="outline"
              type="submit"
              maxW="300"
              height="50"
              isDisabled={
                !isFormValid || operationInProgress || !isRecaptchaVerified
              }
              isLoading={operationInProgress}
              onClick={onSubmitForm}
            >
              SEND YOUR REQUEST
            </Button>
          </Box>
        </Stack>
      </Box>

      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
      />
    </>
  );
};

export default ContactForm;
