import React from "react";

import { SECTIONS } from "../resources/constants";
import Home from "../components/home";
import HowItWorks from "../components/how-it-works";
import ProductFeatures from "../components/product-features";
import WayWeWork from "../components/way-we-work";
import ContactUs from "../components/contact-us";

const COMPONENTS = {
  [SECTIONS.home]: Home,
  [SECTIONS.howItWorks]: HowItWorks,
  [SECTIONS.productFeatures]: ProductFeatures,
  [SECTIONS.wayWeWork]: WayWeWork,
  [SECTIONS.contactUs]: ContactUs,
};

const HomePage = () => {
  return (
    <div style={{ padding: 0, width: "100%" }}>
      {Object.keys(COMPONENTS).map((key, index) => {
        const Section = COMPONENTS[key];
        return <Section key={`${key}-${index}`} />;
      })}
    </div>
  );
};

export default HomePage;
