import React, { useContext, useEffect, useRef } from "react";

import { Flex } from "@chakra-ui/react";
import SectionsContext from "../../resources/SectionContext";

const CoreSlide = ({ category, children }) => {
  const sectionRef = useRef(null);
  const { linkSectionRef } = useContext(SectionsContext);

  useEffect(() => {
    linkSectionRef(sectionRef, category);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      ref={sectionRef}
      bg="transparent"
      pt="36"
      pb="36"
      pl="30"
      pr="30"
      maxWidth="1500px"
      margin="auto"
    >
      {children}
    </Flex>
  );
};

export default CoreSlide;
