import React from "react";

import { Box, Flex, Text, useBreakpointValue, Image } from "@chakra-ui/react";

import CoreSectionContainer from "./core/CoreSectionContainer";
import howItWorks from "../resources/images/howItWorks.gif";

const HowItWorks = () => {
  const maxWidth = useBreakpointValue({ sm: "100%", lg: "60%" });
  return (
    <CoreSectionContainer>
      <Flex
        flexDir="column"
        justify="space-evenly"
        h="50%"
        maxWidth={maxWidth}
        gridGap="20px"
      >
        <Text variant="subtitle">HOW IT WORKS:</Text>

        <Text variant="title">
          Where intelligent dialogues fuel business innovation
        </Text>

        <Text variant="paragraph">
          <span style={{ fontWeight: "bold" }}>iON</span> is a leading platform
          in{" "}
          <span style={{ fontWeight: "bold" }}>
            conventional AI and smart automation
          </span>
          , built to improve how businesses operate. The chatbot is crucial for
          companies aiming to engage their users more effectively and streamline
          their workflows, providing end users with the opportunity to access
          accurate and relevant information they need.
        </Text>

        <Text variant="paragraph">
          With{" "}
          <span style={{ fontWeight: "bold" }}>advanced generative AI </span>
          and
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Natural Language Understanding (NLU), iON {""}
          </span>
          specializes in smart conversations that go beyond the basics. It
          understands and processes human language effectively, ensuring that
          users receive the most accurate and current answers from your
          websites. Equipped with comprehensive analytics and a flexible
          integration system, <span style={{ fontWeight: "bold" }}>iON</span>{" "}
          serves as a
          <span style={{ fontWeight: "bold" }}>
            {" "}
            dynamic communication tool within business frameworks{" "}
          </span>{" "}
          , seamlessly connecting users with the precise information they need
          from your digital resources.
        </Text>
      </Flex>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Image
          id="how-it-works"
          alt="how-it-works"
          height="auto"
          width="100%"
          src={howItWorks}
        />
      </Box>
    </CoreSectionContainer>
  );
};

export default HowItWorks;
