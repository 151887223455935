import axios from "axios";

export default class ApiHelper {
  static sendRequest = async (form = null) => {
    if (!form) {
      throw new Error("Form data is empty");
    }

    const data = {};

    Object.keys(form)?.forEach((keyName) => {
      data[keyName] = form[keyName] || "-";
    });

    return axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/backend/contact`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data)
      .catch(() => {
        throw new Error("API call failed");
      });
  };

  static verifyRecaptcha = async (token = null) => {
    if (!token) {
      throw new Error("Recaptcha token is empty");
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/backend/verify-recaptcha`,
      { token },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response?.data?.success) {
      throw new Error();
    }

    return response?.data?.success;
  };
}
