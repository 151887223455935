import { createContext, useState } from "react";

const SectionsContext = createContext();

export const SectionsProvider = ({ children }) => {
  const [sectionRefs, setSectionRefs] = useState({});

  const linkSectionRef = (ref, sectionName) => {
    setSectionRefs((prevState) => ({ ...prevState, [sectionName]: ref }));
  };

  const scrollTo = (key) => {
    const section = sectionRefs[key];
    section.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <SectionsContext.Provider value={{ sectionRefs, linkSectionRef, scrollTo }}>
      {children}
    </SectionsContext.Provider>
  );
};

export default SectionsContext;
